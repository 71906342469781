import { cn } from '@/common/presentation/utils.ts';

const Skeleton = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'animate-pulse rounded-md bg-global/placeholder-loading-blocks dark:bg-dark/global/placeholder-loading-blocks',
        className,
      )}
      {...props}
    />
  );
};

export { Skeleton };
