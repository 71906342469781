import { create } from 'zustand';

import { type RoomEntity } from '@/features/chat/domain/entities/RoomEntity.ts';

type State = {
  rooms: Array<RoomEntity>;
  isInitialized: boolean;
};

/**
 * Generates an array of empty rooms to be used as the initial state.
 * Based on this array we show a skeleton loader for each room.
 */
const generateEmptyRooms = (): Array<RoomEntity> => {
  return Array.from({ length: 20 }).map((_, index) => {
    return {
      roomId: index.toString(),
      name: '',
      lastMessage: '',
      lastMessageTimestamp: 0,
      members: [],
      createdAt: 0,
      unreadNotificationCount: 0,
    };
  });
};

export const roomsStore = create<State>(() => ({
  rooms: generateEmptyRooms(),
  isInitialized: false,
}));
