import { injectable } from 'inversify';

import { Api, type ApiRequestOptions } from '@/common/data/api/abc/Api.ts';
import { type ApiTokenModel } from '@/features/auth/data/models/ApiTokenModel.ts';
import { type UserDetailsModel } from '@/features/auth/data/models/UserDetailsModel.ts';
import { authStore } from '@/features/auth/data/stores/authStore.ts';
import { type VoipAccountModel } from '@/features/calling/data/models/VoipAccountModel.ts';

@injectable()
export class VoipgridApi extends Api {
  baseUrl = '/voipgrid';

  protected async request<T>(options: ApiRequestOptions) {
    // VoIPGRID API uses a bearer token for all requests that start with 'api/v2/'
    // and normal token authentication for 'api/'.
    options.shouldUseBearerToken = options.url.startsWith('/api/v2/');
    return super.request<T>(options).catch((error) => {
      if (error.response.status === 401) {
        authStore.setState(authStore.getInitialState());
      }
      throw error;
    });
  }

  async getApiToken(email: string, password: string, twoFactorToken: string) {
    return this.request<ApiTokenModel>({
      url: '/api/permission/apitoken/',
      method: 'POST',
      data: { email, password, two_factor_token: twoFactorToken },
    });
  }

  async getUserDetails() {
    return this.request<UserDetailsModel>({
      url: '/api/v2/user/details',
      method: 'GET',
    });
  }

  async getSelectedWebphoneVoipAccount() {
    return this.request<VoipAccountModel>({
      url: '/api/webphone/user/selected_account/',
      method: 'GET',
    });
  }
}

export const voipgridApi = new VoipgridApi();
