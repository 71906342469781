import {
  faArrowRightToBracket,
  faEye,
  faEyeSlash,
  faMessages,
  faPenField,
} from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { VoysInboxLogo } from '@/common/presentation/VoysInboxLogo.tsx';
import { Button } from '@/common/presentation/components/Button.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { Page } from '@/common/presentation/pages/Page.tsx';
import { AuthErrorCodes } from '@/features/auth/data/types/AuthErrorCodes.ts';
import { LoginUsecase } from '@/features/auth/domain/usecases/LoginUsecase.ts';
import { translate } from '@/features/translations/domain/usecases/translate.ts';

export const LoginPage = () => {
  const loginUsecase = useUsecase(LoginUsecase);
  const [willShowTwoFactorTokenInput, setWillShowTwoFactorTokenInput] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const form = useForm();

  const loginQuery = useMutation({
    mutationFn: async () => {
      const { username, password, twoFactorToken } = form.getValues();
      await loginUsecase.call(username, password, twoFactorToken);
    },
    onMutate: () => {
      form.clearErrors();
    },
    // On successful login, navigate to the homepage
    onSuccess: () => {
      navigate('/', { state: { from: '/login' } });
    },
    // On error, if the error is due to two-factor authentication requirement, show the two-factor code input.
    // Other errors will be shown in the JSX below by checking loginQuery.isError.
    onError: (error) => {
      if (error.message === AuthErrorCodes.twoFactorAuthenticationRequired) {
        setWillShowTwoFactorTokenInput(true);
      }
    },
  });

  return (
    <Page title={translate('login')}>
      <div className="font-regular relative flex h-screen flex-col items-center justify-center overflow-hidden bg-[url('/common/presentation/icons/MessagesIcon.tsx')]">
        <FontAwesomeIcon
          icon={faMessages}
          className="absolute -right-28 -top-20 -z-[1] size-[48rem] rotate-[30deg] text-primary-lightest"
        />
        <FontAwesomeIcon
          icon={faMessages}
          className="absolute -bottom-72 -left-56 -z-[1] size-[48rem] rotate-[30deg] text-primary-lightest"
        />
        <div>
          <VoysInboxLogo />
        </div>
        <div>
          <h1 className="font-regular px-6 pb-4 pt-12 text-xl">{translate('login')}</h1>
          <form
            onSubmit={form.handleSubmit(() => loginQuery.mutate())}
            className="flex min-w-100 grow flex-col space-y-6 rounded-md p-6"
          >
            <div className="relative">
              <label
                className="absolute -top-4 left-4 mb-2 block bg-white px-2 pt-2 text-sm font-medium"
                htmlFor="username"
              >
                {translate('username')}
              </label>
              <input
                {...form.register('username')}
                className="border-neutral h-10 w-full rounded-xl border px-4 shadow-inner outline-primary focus-within:placeholder-transparent focus:border-primary active:border-primary"
                type="text"
                id="username"
                name="username"
                placeholder={translate('username_placeholder')}
                autoFocus
              />
            </div>

            <div className="relative">
              <label
                className="absolute -top-4 left-4 z-10 mb-2 block bg-white px-2 pt-2 text-sm font-medium"
                htmlFor="password"
              >
                {translate('password')}
              </label>
              <div className="relative flex">
                <input
                  {...form.register('password')}
                  className="border-neutral h-10 w-full rounded-xl border px-4 shadow-inner outline-primary focus-within:placeholder-transparent focus:border-primary active:border-primary"
                  type={isPasswordVisible ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder={translate('password_placeholder')}
                />
                <Button
                  className="ml-2"
                  type="button"
                  role="switch"
                  aria-label={isPasswordVisible ? translate('hide_password') : translate('show_password')}
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  variant="borderless"
                >
                  {isPasswordVisible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </Button>
              </div>
            </div>

            {willShowTwoFactorTokenInput && (
              <div>
                <label className="mb-2 block text-sm font-medium" htmlFor="2fa">
                  Two-Factor Authentication Code (2FA)
                </label>
                <input
                  {...form.register('twoFactorToken')}
                  className="h-10 w-full rounded-xl border px-4 shadow-inner outline-primary focus-within:placeholder-transparent focus:border-primary active:border-primary"
                  type="2fa"
                  id="2fa"
                />
              </div>
            )}

            <div className="flex justify-between">
              <Button type="submit" aria-label={translate('login')} className="mr-4" disabled={loginQuery.isPending}>
                <FontAwesomeIcon icon={faArrowRightToBracket} className="mr-3 size-4 self-center fill-white" />
                {translate('login')}
              </Button>
              <label className="content-center">{translate('or')}</label>
              {/* TODO make Anchor component -> https://voipgrid.atlassian.net/browse/CI-778  */}
              <a
                href="https://voys.nl/samenstellen"
                target="_blank"
                className="ml-4 flex items-center rounded-md border border-[#E5E5E5] bg-white p-3 font-medium shadow-md hover:shadow-sm disabled:opacity-50"
                aria-label={translate('create_account')}
                role="button"
              >
                <FontAwesomeIcon icon={faPenField} className="fill-neutral-darkest mr-3 size-4 self-center" />
                {translate('create_account')}
              </a>
            </div>

            {loginQuery.isError && (
              <div className="mt-4 rounded-md bg-red-100 p-4 text-red-800">{loginQuery.error.message}</div>
            )}
          </form>
        </div>
      </div>
    </Page>
  );
};
