import { faUserCircle } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HeaderNavigation } from '@/common/presentation/components/HeaderNavigation.tsx';
import { ProfileMenu } from '@/common/presentation/components/ProfileMenu.tsx';
import { SearchBar } from '@/features/search/presentation/components/SearchBar.tsx';

export const Header = () => {
  return (
    <header className="flex items-center border-b p-4">
      <HeaderNavigation />
      <div className="w-96">
        <SearchBar />
      </div>
      <div className="flex flex-1 justify-end">
        <div className="flex items-center gap-2">
          <ProfileMenu />
          <div className="flex size-10 items-center justify-center rounded-full bg-neutral-200">
            <FontAwesomeIcon icon={faUserCircle} className="size-5" />
          </div>
        </div>
      </div>
    </header>
  );
};
