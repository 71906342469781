import { EventEmitter } from 'eventemitter3';
import {
  ClientEvent,
  Direction,
  EventType,
  type MatrixClient,
  type MatrixEvent,
  type Room,
  RoomEvent,
  SyncState,
  TimelineWindow,
  createClient,
} from 'matrix-js-sdk';

import { transformMatrixRoomMessageEventToMessageEntity } from '@/features/chat/data/adapters/transformMatrixRoomMessageEventToMessageEntity.ts';
import { type MessageType } from '@/features/chat/data/types/MessageType.ts';
import { type RoomMessages } from '@/features/chat/data/types/RoomMessages.ts';
import { type TimelineDirection } from '@/features/chat/data/types/TimelineDirection.ts';
import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';
import { type RoomEntity } from '@/features/chat/domain/entities/RoomEntity.ts';
import { isNewMessage } from '@/features/chat/utils/isNewMessage.ts';

const MATRIX_MESSAGE_LIMIT = 20;
const MATRIX_INITIAL_SYNC_LIMIT = 20;

type Events = {
  syncPrepared: () => void;
  roomMessageReceived: (event: MatrixEvent, matrixRoom: Room) => void;
  receiptUpdate: (roomId: string) => void;
};

export class MatrixConnector extends EventEmitter<Events> {
  private client!: MatrixClient;
  /**
   * The timeline window is used to paginate through the messages in a room.
   * URL: https://matrix-org.github.io/matrix-js-sdk/classes/matrix.TimelineWindow.html
   */
  timelineWindow!: TimelineWindow;
  /**
   * We'll keep track of the current room ID to know when the room changes.
   */
  timelineRoomId!: string;

  addClientListeners() {
    this.client.on(RoomEvent.Timeline, async (event) => {
      // On updates within the room timeline it is necessary to extend the timeline window.
      // URL: https://matrix-org.github.io/matrix-js-sdk/classes/matrix.TimelineWindow.html
      if (this.timelineWindow) {
        await this.timelineWindow.paginate(Direction.Forward, MATRIX_MESSAGE_LIMIT);
      }

      // We want to only emit the event when it's a new room message.
      if (isNewMessage(event)) {
        const roomId = this.getRoomIdFromMatrixEvent(event);
        const matrixRoom = this.getMatrixRoom(roomId);
        this.emit('roomMessageReceived', event, matrixRoom);
      }
    });

    this.client.on(RoomEvent.Receipt, (event, room) => {
      // When we've send a mark message as read event we get two RoomEvent.Receipt event back.
      // One for the room and one for the user. We only want to emit the event for the user Room Receipt event.
      if (!event.getRoomId()) {
        this.emit('receiptUpdate', room.roomId);
      }
    });
  }

  /**
   * Create a client and afterwards login to the Matrix service with the token we received from VG.
   * This token is used to authenticate the user with the Matrix service and connect to the right space.
   */
  async createClient(token: string) {
    if (!this.client) {
      this.client = createClient({
        baseUrl: import.meta.env.VITE_MATRIX_HOME_SERVER,
        timelineSupport: true,
      });
    }

    await this.client.login('nl.voys.api_token', {
      identifier: {
        type: 'm.id.user',
        user: 'voys_user',
      },
      initial_device_display_name: 'Conversations App',
      token,
    });

    const userId = this.client.getUserId();
    const accessToken = this.client.getAccessToken();

    return { userId, accessToken };
  }

  /**
   * Adds event listeners on events and then starts the Matrix client, so it can start polling for events.
   */
  async connect() {
    // If the client is already running we don't need to start it again.
    if (this.client.clientRunning) return;

    this.client.once(ClientEvent.Sync, (state) => {
      if (state === SyncState.Prepared) {
        this.addClientListeners();
        this.emit('syncPrepared');
      }
    });

    // TODO: Possibly listen to any errors which can occur during
    //  the startClient method and catch them / handle them.
    //  Instead of returning true we can then handle the error.
    await this.client.startClient({
      initialSyncLimit: MATRIX_INITIAL_SYNC_LIMIT,
    });
  }

  private getRoomIdFromMatrixEvent(event: MatrixEvent) {
    const roomId = event.getRoomId();

    if (!roomId) {
      throw new Error('Room ID not found');
    }

    return roomId;
  }

  private getMatrixRoom(roomId: string) {
    const room = this.client.getRoom(roomId);

    if (!room) {
      throw new Error(`Room with id ${roomId} not found`);
    }

    return room;
  }

  private getMatrixUserId() {
    const userId = this.client.getUserId();

    if (!userId) {
      throw new Error('User id not found');
    }

    return userId;
  }

  /**
   * Retrieves messages from a specific room.
   * This can be based on three different scenarios:
   * 1. Get the latest messages from the room.
   * 2. Get messages before and after a specific event.
   * 3. Get messages from a specific direction (through scrolling).
   */
  async getRoomMessages(room: RoomEntity, eventId = '', direction?: TimelineDirection): Promise<RoomMessages> {
    const matrixRoom = this.getMatrixRoom(room.roomId);
    const userId = this.getMatrixUserId();
    const isFirstLoad = !direction;

    // Create a new timeline window if the room changes or the
    // timeline doesn't exist yet.
    if (!this.timelineWindow || this.timelineRoomId !== room.roomId) {
      this.timelineWindow = new TimelineWindow(this.client, matrixRoom.getUnfilteredTimelineSet());
      this.timelineRoomId = room.roomId;
    }

    if (isFirstLoad) {
      // If we're loading the initial messages,
      // we want to load the latest messages or the messages
      // near a specific eventId.
      await this.timelineWindow.load(eventId, MATRIX_MESSAGE_LIMIT);
    } else {
      // If we're loading more messages, we want to paginate based
      // on the direction we're scrolling.
      const paginateDirection = direction === 'backwards' ? Direction.Backward : Direction.Forward;
      await this.timelineWindow.paginate(paginateDirection, MATRIX_MESSAGE_LIMIT);
    }

    const messages = this.timelineWindow
      .getEvents()
      .filter((event) => event.getType() === EventType.RoomMessage)
      .map((event) => transformMatrixRoomMessageEventToMessageEntity(event, matrixRoom, room, userId));

    return {
      messages,
      isInitialized: isFirstLoad,
      hasMoreBackwards: this.timelineWindow.canPaginate(Direction.Backward),
      hasMoreForwards: this.timelineWindow.canPaginate(Direction.Forward),
    };
  }

  /**
   * Get rooms from the Matrix Memory Store.
   */
  getRooms() {
    return this.client.getRooms();
  }

  /**
   * Send a message read event to the Matrix server to inform a specific message is read.
   */
  async markMessageAsRead(message: MessageEntity) {
    const messageEvent = this.timelineWindow.getEvents().find(({ event }) => event.event_id === message.eventId);

    if (!messageEvent) {
      throw new Error(`Message with id ${message.eventId} not found`);
    }
    await this.client.sendReadReceipt(messageEvent);
  }

  async sendMessage(type: MessageType, message: string, roomId: string) {
    switch (type) {
      case 'text':
        await this.client.sendTextMessage(roomId, message);
        break;
      case 'sticker':
        // Assuming content is a URL to the sticker image
        await this.client.sendStickerMessage(roomId, message, {});
        break;
      case 'image':
        // Assuming content is a URL to the image
        await this.client.sendImageMessage(roomId, message, {});
        break;
    }
  }
}
