import { Outlet } from 'react-router-dom';

import { getChatStore } from '@/features/chat/domain/usecases/getChatStore.ts';

/**
 * The ChatRoutes function component is used to first do a pre-check
 * if the chat store is initialized.
 */
export const ChatRoutes = () => {
  const chatStore = getChatStore();
  const { isInitialized } = chatStore();

  if (!isInitialized) {
    return;
  }

  return <Outlet />;
};
