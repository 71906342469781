import { type RoomSearchResultModel } from '@/features/search/data/models/RoomSearchResultModel.ts';
import { type RoomSearchResultsModel } from '@/features/search/data/models/RoomSearchResultsModel.ts';
import { type RoomSearchResultsEntity } from '@/features/search/domain/entities/RoomSearchResultsEntity.ts';

/**
 * Transforms the given room search results to a RoomSearchResultsEntity.
 */
export function transformRoomSearchResultstoRoomSearchResultsEntity(
  room_results: RoomSearchResultsModel,
): RoomSearchResultsEntity {
  const roomResults: Array<RoomSearchResultModel> = room_results.results.map((result) => {
    return {
      event: {
        id: result.event.id,
      },
      room: {
        id: result.room.id,
        name: result.room.name,
      },
    };
  });

  return {
    rooms: {
      results: roomResults,
      total_results: room_results.total_results,
    },
  };
}
