import { inject, injectable } from 'inversify';

import { SegmentConnector } from '@/features/report/data/api/SegmentConnector.ts';
import { SentryConnector } from '@/features/report/data/api/SentryConnector.ts';

@injectable()
export class ReportRepository {
  constructor(
    @inject(SentryConnector) private sentry: SentryConnector,
    @inject(SegmentConnector) private segment: SegmentConnector,
  ) {}

  async connect() {
    await this.sentry.connect();
    await this.segment.connect();
  }

  async setIdentity(id: string) {
    await this.sentry.identify(id);
    await this.segment.identify(id);
  }

  async trackUserAction(event: string) {
    await this.segment.track(event);
  }
}
