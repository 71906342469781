import { type MatrixEvent, type Room } from 'matrix-js-sdk';

import { pushNotification } from '@/common/domain/usecases/pushNotification.ts';
import { authStore } from '@/features/auth/data/stores/authStore.ts';
import { transformMatrixRoomMessageEventToMessageEntity } from '@/features/chat/data/adapters/transformMatrixRoomMessageEventToMessageEntity.ts';
import { transformMatrixRoomToRoomEntity } from '@/features/chat/data/adapters/transformMatrixRoomToRoomEntity.ts';
import { transformMessagesToMessagesGroupedByDate } from '@/features/chat/data/adapters/transformMessagesToMessagesGroupedByDate.ts';
import { MatrixConnector } from '@/features/chat/data/api/MatrixConnector.ts';
import { chatStore } from '@/features/chat/data/stores/chatStore.ts';
import { currentRoomMessagesStore } from '@/features/chat/data/stores/currentRoomMessagesStore.ts';
import { roomMessageDrafts } from '@/features/chat/data/stores/roomMessageDrafts.ts';
import { roomsStore } from '@/features/chat/data/stores/roomsStore.ts';
import { type MessageType } from '@/features/chat/data/types/MessageType.ts';
import { type TimelineDirection } from '@/features/chat/data/types/TimelineDirection.ts';
import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';
import { isNewMessage } from '@/features/chat/utils/isNewMessage.ts';

class ChatRepository {
  private connector = new MatrixConnector();

  constructor() {
    // Listen to when the sync is prepared to do some initial setup.
    this.connector.on('syncPrepared', () => {
      // We need to populate the rooms store with the rooms from the Matrix client.
      this.saveAllRoomsInStore();
    });

    this.connector.on('roomMessageReceived', (event: MatrixEvent, matrixRoom: Room) => {
      const { roomId } = currentRoomMessagesStore.getState();
      const { userId } = chatStore.getState();

      let room;
      // Try to get the room from the store. If it doesn't exist, save it in the store.
      try {
        room = this.getRoom(matrixRoom.roomId);
      } catch {
        room = this.saveRoomInStore(matrixRoom);
      }

      const eventRoomId = event.getRoomId();

      if (!eventRoomId) return;

      const message = transformMatrixRoomMessageEventToMessageEntity(event, matrixRoom, room, userId);

      // Only add the message to the current room if the message is from the current room.
      if (eventRoomId === roomId) {
        this.addMessageToOpenRoom(message);
      }

      // Only push a notification when the message is not from the current room or the window is hidden.
      // A hidden window means the window is minimized or another tab is open.
      // Checking if the event has an age property makes sure we don't push notifications for old messages.
      if (event.event && (eventRoomId !== roomId || document.visibilityState === 'hidden') && isNewMessage(event)) {
        pushNotification({
          title: `New message from ${message.senderDisplayName}`,
          body: message.message,
          tag: message.eventId,
          data: {
            url: `/${matrixRoom.roomId}/${message.eventId}`,
          },
        });
      }

      this.updateRoomInStore(eventRoomId);
    });

    this.connector.on('receiptUpdate', (roomId: string) => {
      // When a room receipt is updated in terms of the amount of unread notifications for example we need to update
      // our rooms store as well.
      this.updateRoomInStore(roomId);
    });
  }

  async init() {
    const { token } = authStore.getState();
    const { userId, accessToken } = await this.connector.createClient(token);

    if (userId && accessToken) {
      chatStore.setState({ userId, accessToken, isInitialized: true });
      await this.connector.connect();
    }
  }

  async setMessagesInStore(roomId: string, eventId?: string, direction?: TimelineDirection) {
    const room = this.getRoom(roomId);

    const { messages, isInitialized, hasMoreBackwards, hasMoreForwards } = await this.connector.getRoomMessages(
      room,
      eventId,
      direction,
    );

    const messagesGroupedByDate = transformMessagesToMessagesGroupedByDate(messages);
    currentRoomMessagesStore.setState({ messagesGroupedByDate, isInitialized, hasMoreBackwards, hasMoreForwards });
  }

  /**
   * Get specific room by roomId from the rooms store.
   */
  getRoom(roomId: string) {
    const room = roomsStore.getState().rooms.find((room) => room.roomId === roomId);

    if (!room) {
      throw new Error(`Room with id ${roomId} not found`);
    }

    return room;
  }

  getRoomsStore() {
    return roomsStore();
  }

  getMessagesStore() {
    return currentRoomMessagesStore();
  }

  getChatStore() {
    return chatStore;
  }

  getRoomMessageDraftsStore() {
    return roomMessageDrafts;
  }

  setRoomMessageDraft(roomId: string, draft: string) {
    roomMessageDrafts.setState((state) => ({
      drafts: { ...state.drafts, [roomId]: draft },
    }));
  }

  private async addMessageToOpenRoom(message: MessageEntity) {
    const { messagesGroupedByDate } = currentRoomMessagesStore.getState();
    const date = new Date(message.timestamp).toDateString();

    // Check if there is already a date key in the messagesGroupedByDate object.
    // If not, create a new array for the date key.
    if (!Object.keys(messagesGroupedByDate).includes(date)) {
      messagesGroupedByDate[date] = [];
    }

    // Add the message to the date key array.
    messagesGroupedByDate[date].push(message);

    currentRoomMessagesStore.setState({ messagesGroupedByDate });
  }

  setCurrentRoomId(roomId: string) {
    currentRoomMessagesStore.setState({ roomId });
  }

  /**
   * Saves all rooms from the Matrix client in the rooms store.
   */
  private saveAllRoomsInStore() {
    const rooms = this.connector.getRooms();

    // We filter out space rooms before mapping since we have access to the Room object
    // Based on the RoomEntity from our map we can filter out rooms without messages
    const transformedRooms = rooms
      .filter((room) => !room.isSpaceRoom())
      .map((room) => transformMatrixRoomToRoomEntity(room))
      .filter((room) => room.lastMessage);

    roomsStore.setState({ rooms: transformedRooms, isInitialized: true });
  }

  private saveRoomInStore(matrixRoom: Room) {
    const transformedRoom = transformMatrixRoomToRoomEntity(matrixRoom);
    roomsStore.setState((prev) => ({ rooms: [...prev.rooms, transformedRoom] }));
    return transformedRoom;
  }

  private updateRoomInStore(roomId: string) {
    const rooms = this.connector.getRooms();
    const foundRoom = rooms.find((room) => room.roomId === roomId);
    const storeRooms = roomsStore.getState().rooms;

    if (foundRoom) {
      const transformedRoom = transformMatrixRoomToRoomEntity(foundRoom);
      const storedRoom = storeRooms.find((room) => room.roomId === roomId);

      if (storedRoom) {
        const updatedRooms = storeRooms.map((room) => {
          if (room.roomId === roomId) {
            return transformedRoom;
          }
          return room;
        });

        roomsStore.setState({ rooms: updatedRooms });
      }
    }
  }

  async markMessageAsRead(readMessage: MessageEntity) {
    // Only mark the message as read if it's not already read.
    if (!readMessage?.isRead) {
      await this.connector.markMessageAsRead(readMessage);

      // Manually update the message in the store to mark it as read.
      // To avoid having to update the entire currentRoomMessagesStore.
      const { messagesGroupedByDate } = currentRoomMessagesStore.getState();
      const updatedMessagesGroupedByDate = transformMessagesToMessagesGroupedByDate(
        Object.values(messagesGroupedByDate)
          .flat() // Flatten the messagesGroupedByDate object to an array of only the messages.
          .map((message) => (message.eventId === readMessage.eventId ? { ...message, isRead: true } : message)),
      );
      currentRoomMessagesStore.setState({ messagesGroupedByDate: updatedMessagesGroupedByDate });
    }
  }

  async sendMessage(type: MessageType, message: string, roomId: string) {
    await this.connector.sendMessage(type, message, roomId);
  }
}

export const chatRepository = new ChatRepository();
