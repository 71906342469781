import { useIntersectionObserver } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
  rootRef?: React.RefObject<HTMLDivElement>;
  handleTopIntersection?: () => void;
  handleBottomIntersection?: () => void;
};

/**
 * An Infinite Scroll component that triggers a callback when the top or bottom of the scrollable area is reached.
 */
export const InfiniteScroll = ({ children, rootRef, handleTopIntersection, handleBottomIntersection }: Props) => {
  const [topRef, topEntry] = useIntersectionObserver({
    root: rootRef?.current,
    threshold: 1,
    rootMargin: '400px',
  });
  const [bottomRef, bottomEntry] = useIntersectionObserver({
    root: rootRef?.current,
    threshold: 1,
    rootMargin: '400px',
  });

  // We need to keep track of the last intersection time to prevent multiple calls to the intersection callback.
  const [lastTopIntersectionTime, setLastTopIntersectionTime] = useState<number | null>(null);
  const [lastBottomIntersectionTime, setLastBottomIntersectionTime] = useState<number | null>(null);

  useEffect(() => {
    if (topEntry?.isIntersecting && topEntry.time !== lastTopIntersectionTime) {
      setLastTopIntersectionTime(topEntry.time);
      if (handleTopIntersection) handleTopIntersection();
    }
  }, [topEntry, lastTopIntersectionTime, handleTopIntersection]);

  useEffect(() => {
    if (bottomEntry?.isIntersecting && bottomEntry.time !== lastBottomIntersectionTime) {
      setLastBottomIntersectionTime(bottomEntry.time);
      if (handleBottomIntersection) handleBottomIntersection();
    }
  }, [bottomEntry, lastBottomIntersectionTime, handleBottomIntersection]);

  return (
    <div>
      <div ref={topRef}></div>
      {children}
      <div ref={bottomRef}></div>
    </div>
  );
};
