import { FlagProvider, type IConfig } from '@unleash/proxy-client-react';
import { StrictMode, createContext } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
// The reflect-metadata allows Typescript to use the decorators which is needed for our dependency injection library.
import 'reflect-metadata';

import { App } from '@/App.tsx';
import { container } from '@/container.ts';

const { VITE_FEATURE_FLAGS_API_URL, VITE_FEATURE_FLAGS_API_CLIENT_KEY } = import.meta.env;

const config: IConfig = {
  url: VITE_FEATURE_FLAGS_API_URL,
  clientKey: VITE_FEATURE_FLAGS_API_CLIENT_KEY,
  refreshInterval: 60,
  appName: 'conversations',
};

const element = document.getElementById('root')!;

export const ContainerContext = createContext(container);

createRoot(element).render(
  /*
   * https://react.dev/reference/react/StrictMode#strictmode
   * Your components will re-render an extra time to find bugs caused by impure rendering.
   * Your components will re-run Effects an extra time to find bugs caused by missing Effect cleanup.
   * Your components will be checked for usage of deprecated APIs.
   */
  <StrictMode>
    <FlagProvider config={config}>
      <ContainerContext.Provider value={container}>
        <App />
      </ContainerContext.Provider>
    </FlagProvider>
  </StrictMode>,
);
