import axios from 'axios';
import { injectable } from 'inversify';

import { authStore } from '@/features/auth/data/stores/authStore.ts';

export interface ApiRequestOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: object;
  shouldUseBearerToken?: boolean;
}

@injectable()
export abstract class Api {
  baseUrl = '';

  protected async request<T>({ url, method, data, shouldUseBearerToken = true }: ApiRequestOptions) {
    const { token, email } = authStore.getState();

    return await axios.request<T>({
      url: `${this.baseUrl}${url}`,
      method,
      data,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: token && (shouldUseBearerToken ? `Bearer ${token}` : `Token ${email}:${token}`),
      },
    });
  }
}
