/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { injectable } from 'inversify';

const { VITE_SENTRY_DSN, VITE_ENVIRONMENT } = import.meta.env;

@injectable()
export class SentryConnector {
  async connect() {
    if (Sentry.isInitialized()) {
      return;
    }

    if (!VITE_SENTRY_DSN) {
      console.warn('Sentry: No VITE_SENTRY_DSN in environment variables');
      return;
    }

    Sentry.init({
      dsn: VITE_SENTRY_DSN,
      environment: VITE_ENVIRONMENT,
      integrations: [Sentry.browserTracingIntegration()],
      initialScope: {
        tags: { scope: 'Conversations' },
      },
    });

    console.log('Sentry: Initialized');
  }

  async identify(userId: string) {
    Sentry.setUser({ id: userId });
    console.log(`Sentry: Setting user to ${userId}`);
  }
}
