import { type RoomSearchResultModel } from '@/features/search/data/models/RoomSearchResultModel.ts';

/**
 * Generates an array of empty Room search results to be used as the initial state.
 * Based on this array we show a skeleton loader for each search result.
 */
export const generateMockRoomSearchResults = (): Array<RoomSearchResultModel> => {
  return Array.from({ length: 10 }).map((_, index) => {
    return {
      event: {
        id: index.toString(),
      },
      room: {
        name: 'This is a mocked room name',
        id: index.toString(),
      },
    };
  });
};
