/* eslint-disable no-console */
import i18next, { t } from 'i18next';

import de from '@/locales/de-DE.json';
import en from '@/locales/en-US.json';
import es from '@/locales/es-ES.json';
import fr from '@/locales/fr-FR.json';
import nl from '@/locales/nl-NL.json';
import pt from '@/locales/pt-PT.json';

class TranslationRepository {
  constructor() {
    // eslint-disable-next-line import/no-named-as-default-member
    i18next.init({
      resources: {
        'en-US': { translation: en },
        'nl-NL': { translation: nl },
        'de-DE': { translation: de },
        'fr-FR': { translation: fr },
        'es-ES': { translation: es },
        'pt-PT': { translation: pt },
      },

      lng: window.navigator.language,
      fallbackLng: 'en-US',
      saveMissing: true,
      missingKeyHandler: (languages, _, key) => {
        console.warn(`Missing translation for key '${key}' in languages '${languages.join(', ')}'`);
      },
    });
  }

  translate(key: string, params?: Record<string, string>) {
    return t(key, params);
  }
}

export const translationRepository = new TranslationRepository();
