import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  firstName: string;
  lastName: string;
  clientName: string;
  uuid: string;
  email: string;
  token: string;
  isAuthenticated: boolean;
};

export const authStore = create<State>()(
  persist<State>(
    () => ({
      firstName: '',
      lastName: '',
      clientName: '',
      uuid: '',
      email: '',
      token: '',
      isAuthenticated: false,
    }),
    {
      name: 'auth',
    },
  ),
);
