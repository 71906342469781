/* eslint-disable no-console */
import { AnalyticsBrowser } from '@segment/analytics-next';
import { injectable } from 'inversify';

const { VITE_SEGMENT_WRITE_KEY } = import.meta.env;

@injectable()
export class SegmentConnector {
  analytics?: AnalyticsBrowser;

  async connect() {
    if (this.analytics) {
      return;
    }

    if (!VITE_SEGMENT_WRITE_KEY) {
      console.warn('Segment: No VITE_SEGMENT_WRITE_KEY in environment variables');
      return;
    }

    this.analytics = AnalyticsBrowser.load({ writeKey: VITE_SEGMENT_WRITE_KEY });
    console.log('Segment: Initialized');

    // TODO identify anonymously
    // https://voipgrid.atlassian.net/browse/CI-756
  }

  async identify(identity: string) {
    const { language, userAgent } = navigator;
    await this.analytics?.identify(identity, { language, userAgent });
    console.log(`Segment: Setting user to ${identity}`);
  }

  async track(event: string) {
    await this.analytics?.track(event);
  }
}
