/**
 * Highlight one or multiple words in a given text that match the search query.
 */
export function highlightWords(text: string, searchQuery: string) {
  // Split the search query into words and filter out empty strings.
  const words = searchQuery.split(' ').filter(Boolean);

  // Combine the words into a regex pattern, e.g.
  // (word1|word2|word3)
  const regex = new RegExp(`(${words.join('|')})`, 'gi');
  // Split the text into parts that match the regex
  // e.g. ['sentence1', 'word1', 'sentence2', 'word2', 'sentence3']
  const parts = text.split(regex);

  // We wrap the matching parts in a span with a highlighting color.
  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="bg-[#FFE18B] text-[#5D4711]">
        {part}
      </span>
    ) : (
      part
    ),
  );
}
