import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Header } from '@/common/presentation/components/Header.tsx';
import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';

/**
 * The ProtectedRoutes function component is used to protect certain routes that require user authentication.
 * It checks if the user is authenticated and then either renders the route or redirects to the login page.
 */
export const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const authStore = useUsecase(GetAuthStoreUsecase).call();
  const { isAuthenticated } = authStore();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="grid-container">
      <Header />
      <Outlet />
    </div>
  );
};
