import dayjs from 'dayjs';

import { translate } from '@/features/translations/domain/usecases/translate.ts';

/**
 *  Matches the WhatsApp date and time format.
 *  - If the date and time is within the current day, it will return the time.
 *  - If it represents yesterday's date, it will return 'Yesterday'.
 *  - If it's within the last week but not yesterday, it will return the day of the week.
 *  - For any date more than a week ago, it will return the full date in 'DD/MM/YYYY' format.
 *  @param date - The date to format.
 *  @param shouldShowToday - If true, it will return 'Today' for the current day. If false, it will return the time (HH:mm).
 */
export const formatDateToDateString = (date: Date, shouldShowToday: boolean = false): string => {
  let shownDate = dayjs(date).format('DD/MM/YYYY');
  const parsedDate = dayjs(date);
  const now = dayjs();
  const yesterday = now.subtract(1, 'day');
  const weekAgo = now.subtract(1, 'week');
  if (parsedDate.isAfter(weekAgo)) {
    const lowerCaseDay = dayjs(date).format('dddd').toLowerCase();
    shownDate = translate(lowerCaseDay);
  }
  if (parsedDate.isSame(now, 'day')) {
    return shouldShowToday ? translate('today') : parsedDate.format('HH:mm');
  }
  if (parsedDate.isSame(yesterday, 'day')) {
    shownDate = translate('yesterday');
  }

  return shownDate;
};
