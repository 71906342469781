import { NavLink } from 'react-router-dom';

import { InboxListCard } from '@/common/presentation/components/InboxListCard.tsx';
import { getRoomsStore } from '@/features/chat/domain/usecases/getRoomsStore.ts';
import { translate } from '@/features/translations/domain/usecases/translate.ts';

export const RoomList = () => {
  const { rooms, isInitialized } = getRoomsStore();

  return (
    <div className="room-list border-r">
      <div>
        {rooms.length === 0 && isInitialized && (
          <div className="flex h-full flex-col items-center justify-center p-4">
            <span className="mb-4 block w-full rounded-md bg-blue-100 px-4 py-2 text-center font-medium text-blue-700">
              {translate('no_chats')}
            </span>
          </div>
        )}
        {rooms.map((room) => (
          <NavLink to={`/${room.roomId}`} key={room.roomId}>
            {({ isActive }) => (
              <InboxListCard
                name={room.name}
                description={room.lastMessage}
                timestamp={room.lastMessageTimestamp}
                shouldShowDot={room.unreadNotificationCount > 0}
                isLoading={!isInitialized}
                isSelected={isActive}
              />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
