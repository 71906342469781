import { type interfaces } from 'inversify';
import { useContext, useMemo } from 'react';

import { ContainerContext } from '@/main.tsx';

/**
 * Use this hook to get an instance of an usecase class with the context given by the Main.tsx.
 *
 * @example:
 * import { LoginUsecase } from '@/features/auth/domain/usecases/LoginUsecase.ts';
 * import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
 *
 * // This will give you an instance of the LoginUsecase class.
 * const loginUsecase = useUsecase(LoginUsecase);
 *
 * // This will call the usecase method with the given parameters.
 * loginUsecase.call('username', 'password');
 */
export function useUsecase<T>(usecase: interfaces.ServiceIdentifier<T>): T {
  const container = useContext(ContainerContext);
  return useMemo(() => container.get(usecase), [container, usecase]);
}
