import { translate } from '@/features/translations/domain/usecases/translate.ts';

export const NoSearchResults = () => {
  return (
    <div className="w-full rounded p-4">
      <div className="text-center font-medium text-black">{translate('no_search_results')}</div>
      <div className="text-center text-neutral-700">{translate('no_search_results_message')}</div>
    </div>
  );
};
