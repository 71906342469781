import { inject, injectable } from 'inversify';

import { SearchRepository } from '@/features/search/data/repositories/SearchRepository.ts';

@injectable()
export class GetRoomSearchResultsUsecase {
  constructor(@inject(SearchRepository) private searchRepository: SearchRepository) {}

  async call(searchQuery: string) {
    return await this.searchRepository.getRoomSearchResults(searchQuery);
  }
}
