import { inject, injectable } from 'inversify';

import { AuthRepository } from '@/features/auth/data/repositories/AuthRepository.ts';

@injectable()
export class LoginUsecase {
  constructor(@inject(AuthRepository) private authRepository: AuthRepository) {}

  async call(username: string, password: string, twoFactorToken: string) {
    return await this.authRepository.login(username, password, twoFactorToken);
  }
}
