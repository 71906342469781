import { chatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';

/**
 * Gets the rooms store with a sorted list of rooms from new to old.
 */
export function getRoomsStore() {
  const { rooms, isInitialized } = chatRepository.getRoomsStore();
  rooms.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);

  return { rooms, isInitialized };
}
