type PushNotificationOptions = NotificationOptions & {
  title: string;
};

/**
 * Pushes an OS-level notification to the user's device.
 */
export function pushNotification({ title, ...options }: PushNotificationOptions) {
  Notification.requestPermission((result) => {
    if (result !== 'granted') return;

    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(title, { icon: 'logos/logo-192.png', ...options });
    });
  });
}
