import { faEllipsis } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/common/presentation/components/dropdown-menu.tsx';
import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';
import { CopyTextMenuItem } from '@/features/chat/presentation/components/CopyTextMenuItem.tsx';
import { translate } from '@/features/translations/domain/usecases/translate.ts';

type Props = {
  direction: 'start' | 'end';
  message: MessageEntity;
};

export const MessageMenu = ({ direction, message }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPointerDownOutside, setIsPointerDownOutside] = useState(false);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="group">
        <div
          className={classNames(
            'flex size-4 items-center group-hover:visible group-focus:visible',
            isOpen ? 'visible' : 'invisible',
          )}
        >
          <span className="sr-only">{translate('open_message_options')}</span>
          <FontAwesomeIcon icon={faEllipsis} className="text-gray-400" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={classNames(
          'absolute z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          {
            '-right-2': direction === 'end',
            '-left-2': direction === 'start',
          },
        )}
        onCloseAutoFocus={(e) => {
          // Only prevent autofocus if the user clicked outside the dropdown.
          if (isPointerDownOutside) {
            e.preventDefault();
            setIsPointerDownOutside(false);
          }
        }}
        onPointerDownOutside={() => {
          setIsPointerDownOutside(true);
        }}
      >
        <CopyTextMenuItem textToCopy={message.message} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
