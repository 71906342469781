import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/common/presentation/utils.ts';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-button/primary/bgColor/default focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-55 disabled:shadow-none',
  {
    variants: {
      variant: {
        primary:
          'bg-button/primary/bgColor/default hover:bg-button/primary/bgColor/hover active:bg-button/primary/bgColor/pressed disabled:bg-button/primary/bgColor/disabled text-text/onStrongBg/color fill-icon/onStrongBg/fillColor shadow-button/primary hover:shadow-button/primary/hover active:shadow-button/primary/active',
        neutral:
          'border text-text/neutral/color/body/default fill-icon/neutral/fillColor/default border-button/neutral/borderColor/default bg-controls/neutral/bgColor/default hover:fill-icon/neutral/fillColor/hover hover:border-button/neutral/borderColor/hover active:border-button/neutral/borderColor/pressed hover:bg-controls/neutral/bgColor/hover active:fill-icon/neutral/fillColor/pressed disabled:bg-button/neutral/bgColor/disabled active:bg-controls/neutral/bgColor/pressed shadow-button/neutral hover:shadow-button/neutral/hover active:shadow-button/neutral/active',
        borderless:
          'text-text/neutral/color/body/default fill-icon/neutral/fillColor/default hover:fill-icon/neutral/fillColor/hover active:fill-icon/neutral/fillColor/pressed bg-controls/neutral/bgColor/default hover:bg-controls/neutral/bgColor/hover active:bg-controls/neutral/bgColor/pressed active:shadow-button/neutral/active',
      },
      size: {
        medium: 'p-4 active:pb-3.5 active:pt-4.5',
        small: 'p-3 active:pb-2.5 active:pt-3.5',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, ...props }, ref) => {
  return <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
