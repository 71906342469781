import JsSIP from 'jssip';

import { type SipAccountModel } from '@/features/calling/data/models/SipAccountModel.ts';

class SipWebsocket {
  async connect(account: SipAccountModel) {
    const socket = new JsSIP.WebSocketInterface(import.meta.env.VITE_SIP_ENDPOINT);

    const configuration = {
      sockets: [socket],
      uri: account.uri,
      password: account.password,
    };

    return new JsSIP.UA(configuration);
  }
}

export const sipWebsocket = new SipWebsocket();
