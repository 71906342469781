import { inject, injectable } from 'inversify';

import { ReportRepository } from '@/features/report/data/repository/ReportRepository.ts';

@injectable()
export class ConnectToReportingUsecase {
  constructor(@inject(ReportRepository) private repo: ReportRepository) {}

  async call() {
    await this.repo.connect();
  }
}
