interface Props {
  text: string;
}

export function MessageDateBubble({ text }: Props) {
  return (
    <div className="relative">
      <div className="relative flex justify-center">
        <div className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
          {text}
        </div>
      </div>
    </div>
  );
}
