import axios, { AxiosHeaders } from 'axios';
import { injectable } from 'inversify';

export interface HolodeckApiRequestOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: object;
  shouldUseBearerToken?: boolean;
  token?: string;
  customHeaders?: Record<string, string>;
}

@injectable()
export abstract class HolodeckApi {
  domain = '';
  service = '';

  protected async request<T>({ url, method, data, token, customHeaders }: HolodeckApiRequestOptions) {
    const headers = new AxiosHeaders(customHeaders);

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // Below is a workaround to ignore the domain reference when running locally
    // since services run locally don't have a domain defined in their URL.
    if (import.meta.env.VITE_HOLODECK_URL.startsWith('http://localhost')) {
      this.domain = '';
    }

    return await axios.request<T>({
      url: `/holodeck${this.domain}${this.service}${url}`,
      method,
      data,
      headers,
    });
  }
}
