import { inject, injectable } from 'inversify';

import { ReportRepository } from '@/features/report/data/repository/ReportRepository.ts';

@injectable()
export class SetIdentityUsecase {
  constructor(@inject(ReportRepository) private repo: ReportRepository) {}

  async call(id: string) {
    await this.repo.setIdentity(id);
  }
}
