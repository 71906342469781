import { type ChatSearchResultModel } from '@/features/search/data/models/ChatSearchResultModel.ts';

/**
 * Generates an array of empty Chat search results to be used as the initial state.
 * Based on this array we show a skeleton loader for each search result.
 */
export const generateMockChatSearchResults = (): Array<ChatSearchResultModel> => {
  return Array.from({ length: 10 }).map((_, index) => {
    return {
      event: {
        id: index.toString(),
        timestamp: 0,
      },
      room: {
        name: 'This is a mocked room name',
        id: index.toString(),
      },
      sender: {
        id: index.toString(),
        name: 'This is a mocked sender name',
      },
      message: 'This is a mocked message, with some text to show how it looks like',
    };
  });
};
