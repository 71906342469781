import { inject, injectable } from 'inversify';

import { AuthRepository } from '@/features/auth/data/repositories/AuthRepository.ts';

@injectable()
export class LogoutUsecase {
  constructor(@inject(AuthRepository) private authRepository: AuthRepository) {}

  call() {
    this.authRepository.logout();
  }
}
