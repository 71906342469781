import { create } from 'zustand';

import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';

type State = {
  messagesGroupedByDate: Record<string, Array<MessageEntity>>;
  isInitialized: boolean;
  roomId: string;
  hasMoreBackwards: boolean;
  hasMoreForwards: boolean;
};

export const currentRoomMessagesStore = create<State>(() => ({
  messagesGroupedByDate: {},
  isInitialized: false,
  roomId: '',
  hasMoreBackwards: false,
  hasMoreForwards: false,
}));
