import { type ChatSearchResultModel } from '@/features/search/data/models/ChatSearchResultModel.ts';
import { type ChatSearchResultsModel } from '@/features/search/data/models/ChatSearchResultsModel.ts';
import { type ChatSearchResultsEntity } from '@/features/search/domain/entities/ChatSearchResultsEntity.ts';

/**
 * Transforms the given chat search results to a ChatSearchResultsEntity.
 */
export function transformChatSearchResultstoChatSearchResultsEntity(
  chat_results: ChatSearchResultsModel,
): ChatSearchResultsEntity {
  const chatResults: Array<ChatSearchResultModel> = chat_results.results.map((result) => {
    return {
      event: {
        id: result.event.id,
        timestamp: result.event.timestamp,
      },
      room: {
        id: result.room.id,
        name: result.room.name,
      },
      sender: {
        id: result.sender.id,
        name: result.sender.name,
      },
      message: result.message,
    };
  });

  return {
    chats: {
      results: chatResults,
      total_results: chat_results.total_results,
    },
  };
}
