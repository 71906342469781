import dayjs from 'dayjs';

import { getRoom } from '@/features/chat/domain/usecases/getRoom.ts';
import { translate } from '@/features/translations/domain/usecases/translate.ts';

interface Props {
  roomId: string;
}

export const ChatHeader = ({ roomId }: Props) => {
  const room = getRoom(roomId);

  return (
    <div className="flex h-16 items-center text-lg font-medium text-gray-700">
      <span className="flex-1 font-semibold">{room.name}</span>
      <span>
        {translate('started_on')}&nbsp;
        {dayjs(room.lastMessageTimestamp).format('DD/MM/YYYY')}
      </span>
    </div>
  );
};
