import { Container } from 'inversify';

import { VoipgridApi, voipgridApi } from '@/common/data/api/VoipgridApi.ts';
import { AuthRepository } from '@/features/auth/data/repositories/AuthRepository.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { LoginUsecase } from '@/features/auth/domain/usecases/LoginUsecase.ts';
import { LogoutUsecase } from '@/features/auth/domain/usecases/LogoutUsecase.ts';
import { SegmentConnector } from '@/features/report/data/api/SegmentConnector.ts';
import { SentryConnector } from '@/features/report/data/api/SentryConnector.ts';
import { ReportRepository } from '@/features/report/data/repository/ReportRepository.ts';
import { ConnectToReportingUsecase } from '@/features/report/domain/usecases/ConnectToReportingUsecase.ts';
import { SetIdentityUsecase } from '@/features/report/domain/usecases/SetIdentityUsecase.ts';
import { TrackUserActionUsecase } from '@/features/report/domain/usecases/TrackUserActionUsecase.ts';
import { SearchSphereApi } from '@/features/search/data/api/holodeck/SearchSphereApi.ts';
import { SearchRepository } from '@/features/search/data/repositories/SearchRepository.ts';
import { GetChatSearchResultsUsecase } from '@/features/search/domain/usecases/GetChatSearchResultsUsecase.ts';
import { GetRoomSearchResultsUsecase } from '@/features/search/domain/usecases/GetRoomSearchResultsUsecase.ts';

// Create a new container instance which will be used to bind the usecases and repositories.
const container = new Container();

// common
container.bind(VoipgridApi).toConstantValue(voipgridApi);
container.bind(SearchSphereApi).toSelf();

// features/auth
container.bind(AuthRepository).toSelf();
container.bind(GetAuthStoreUsecase).toSelf();
container.bind(LogoutUsecase).toSelf();
container.bind(LoginUsecase).toSelf();

// features/report
container.bind(SentryConnector).toSelf();
container.bind(SegmentConnector).toSelf();
container.bind(ReportRepository).toSelf();
container.bind(ConnectToReportingUsecase).toSelf();
container.bind(SetIdentityUsecase).toSelf();
container.bind(TrackUserActionUsecase).toSelf();

// features/search
container.bind(SearchRepository).toSelf();
container.bind(GetChatSearchResultsUsecase).toSelf();
container.bind(GetRoomSearchResultsUsecase).toSelf();

export { container };
