import { inject, injectable } from 'inversify';

import { transformChatSearchResultstoChatSearchResultsEntity } from '@/features/search/data/adapters/transformChatSearchResultstoChatSearchResultsEntity.ts';
import { transformRoomSearchResultstoRoomSearchResultsEntity } from '@/features/search/data/adapters/transformRoomSearchResultstoRoomSearchResultsEntity.ts';
import { SearchSphereApi } from '@/features/search/data/api/holodeck/SearchSphereApi.ts';
import { type ChatSearchResultsEntity } from '@/features/search/domain/entities/ChatSearchResultsEntity.ts';
import { type RoomSearchResultsEntity } from '@/features/search/domain/entities/RoomSearchResultsEntity.ts';

@injectable()
export class SearchRepository {
  constructor(@inject(SearchSphereApi) private searchSphereApi: SearchSphereApi) {}

  async getChatSearchResults(searchQuery: string): Promise<ChatSearchResultsEntity> {
    return this.searchSphereApi.getChatSearchResults(searchQuery).then((chatResults) => {
      return transformChatSearchResultstoChatSearchResultsEntity(chatResults.data);
    });
  }

  async getRoomSearchResults(searchQuery: string): Promise<RoomSearchResultsEntity> {
    return this.searchSphereApi.getRoomSearchResults(searchQuery).then((roomResults) => {
      return transformRoomSearchResultstoRoomSearchResultsEntity(roomResults.data);
    });
  }
}
