import { faArrowLeftFromBracket, faChevronDown } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { LogoutUsecase } from '@/features/auth/domain/usecases/LogoutUsecase.ts';
import { translate } from '@/features/translations/domain/usecases/translate.ts';

export const ProfileMenu = () => {
  const authStore = useUsecase(GetAuthStoreUsecase).call();
  const { firstName } = authStore();
  const logout = useUsecase(LogoutUsecase);

  return (
    <Menu as="div" className="relative">
      <MenuButton className="text-md flex items-center gap-1 font-semibold text-primary">
        {firstName}
        <span className="sr-only">Open profile options</span>
        <FontAwesomeIcon icon={faChevronDown} className="size-4 text-gray-700" aria-hidden={true} />
      </MenuButton>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="absolute -top-1.5 right-6 -z-10 size-3 rotate-45 bg-white shadow-lg ring-1 ring-black ring-opacity-5"></div>
          <div className="rounded-md bg-white py-1">
            <MenuItem>
              <a
                href="#"
                className="group flex items-center px-4 py-2 text-sm text-neutral-700 hover:bg-neutral-50 hover:text-neutral-900"
                onClick={() => logout.call()}
              >
                <FontAwesomeIcon icon={faArrowLeftFromBracket} className="mr-2 size-4" />
                <span className="font-semibold">{translate('logout')}</span>
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
