import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  drafts: Record<string, string>;
};

export const roomMessageDrafts = create<State>()(
  persist<State>(
    () => ({
      drafts: {},
    }),
    {
      name: 'roomMessageDrafts',
    },
  ),
);
