import { create } from 'zustand';

type State = {
  userId: string;
  accessToken: string;
  isInitialized: boolean;
};

export const chatStore = create<State>(() => ({
  userId: '',
  accessToken: '',
  isInitialized: false,
}));
