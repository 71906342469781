import { useDocumentTitle } from '@uidotdev/usehooks';
import { type ReactNode } from 'react';

type PageProps = {
  title: string;
  children: ReactNode;
};

export const Page = ({ children, title }: PageProps) => {
  useDocumentTitle(`Voys Inbox - ${title}`);
  return <>{children}</>;
};
