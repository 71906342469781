import { useCallback, useEffect, useRef, useState } from 'react';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { formatDateToDateString } from '@/common/presentation/utils/formatDateToDateString.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { type TimelineDirection } from '@/features/chat/data/types/TimelineDirection.ts';
import { getMessagesStore } from '@/features/chat/domain/usecases/getMessagesStore.ts';
import { setMessagesInStore } from '@/features/chat/domain/usecases/setMessagesInStore.ts';
import { ChatInput } from '@/features/chat/presentation/components/ChatInput.tsx';
import { InfiniteScroll } from '@/features/chat/presentation/components/InfiniteScroll.tsx';
import { Message } from '@/features/chat/presentation/components/Message.tsx';
import { MessageDateBubble } from '@/features/chat/presentation/components/MessageDateBubble.tsx';

type Props = {
  roomId: string;
  urlEventId?: string;
};

export const ChatView = ({ roomId, urlEventId }: Props) => {
  const authStore = useUsecase(GetAuthStoreUsecase).call();
  const { clientName } = authStore();

  const { messagesGroupedByDate, hasMoreBackwards, hasMoreForwards } = getMessagesStore();
  const amountOfMessages = Object.keys(messagesGroupedByDate).length;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const [hasScrolledInitially, setHasScrolledInitially] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageTopRef = useRef<HTMLDivElement | null>(null);
  const eventRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setMessagesInStore(roomId, urlEventId);
  }, [roomId, urlEventId]);

  useEffect(() => {
    if (messagesGroupedByDate && amountOfMessages > 0 && !hasScrolledInitially) {
      if (urlEventId) {
        eventRef.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
      } else {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
      }
      setHasScrolledInitially(true);
    }
  }, [urlEventId, messagesGroupedByDate, amountOfMessages, hasScrolledInitially]);

  useEffect(() => {
    setHasScrolledInitially(false);
  }, [roomId]);

  const handleLoadMore = useCallback(
    async (direction: TimelineDirection) => {
      if (!hasMoreForwards && direction === 'forwards') return;
      if (!hasMoreBackwards && direction === 'backwards') return;

      const scrollableNode = scrollableRootRef.current;

      const oldNode = messageTopRef.current;
      await setMessagesInStore(roomId, urlEventId, direction);

      if (direction === 'backwards') {
        scrollableNode?.scrollTo({
          top: oldNode?.offsetTop,
        });
      }
    },
    [urlEventId, roomId, hasMoreForwards, hasMoreBackwards],
  );

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div
        className="relative flex-1 overflow-y-scroll rounded-md border bg-gray-100 p-4 pt-0 shadow-inner"
        ref={rootRef}
      >
        <InfiniteScroll
          rootRef={rootRef}
          handleTopIntersection={async () => handleLoadMore('backwards')}
          handleBottomIntersection={async () => handleLoadMore('forwards')}
        >
          {Object.entries(messagesGroupedByDate).map(([date, messages], mapIndex) => (
            <div key={date} className="flex flex-col">
              <div className="sticky top-0 flex justify-center py-4">
                <MessageDateBubble text={formatDateToDateString(new Date(date), true)} />
              </div>
              {messages.map((message, index) => (
                <div
                  key={message.eventId}
                  ref={(node) => {
                    if (message.eventId === urlEventId) {
                      eventRef.current = node;
                    }
                    if (index === 0 && mapIndex === 0) {
                      messageTopRef.current = node;
                    }
                  }}
                  className="mb-4 last:mb-0"
                >
                  <Message
                    urlEventId={urlEventId}
                    key={message.eventId}
                    message={message}
                    hasSameSenderAsPreviousMessage={messages[index - 1]?.senderId === message.senderId}
                    clientName={clientName}
                    inboxRef={rootRef}
                  />
                </div>
              ))}
            </div>
          ))}
        </InfiniteScroll>
        <div ref={messagesEndRef} />
      </div>
      <ChatInput roomId={roomId} messagesEndRef={messagesEndRef} />
    </div>
  );
};
